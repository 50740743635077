* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 100;
  overflow-x: hidden;
  position: relative;
  background-color: white !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  font-size: 14px;
  color: #042b4e;
}

nav.navbar a.navbar-brand {
  flex-shrink: 0;
  color: #042B4E;
}

nav.navbar .nav-link .Link{
  margin-left:30px;
  margin-right:30px;
  color: #042B4E;
}

.navbar-nav .nav-link {
  margin-right: 30px; /* Ajusta el valor según el espacio deseado */
  margin-left: 30px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 5px;
  padding: 0 25px;
  font-size: 40px;
  opacity: 1px;
}

span.navbar-text{
  display: flex;
  align-items: center;
  color: #042B4E;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #282c34;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #282c34;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon::before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expander="true"] .navbar-toggler-icon:after{
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expander="true"] .navbar-toggler-icon:before{
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expander="true"] .navbar-toggler-icon{
  border-color: #282c34;
}

.custom-dropdown .dropdown-menu {
  /* Estilos para el menú desplegable */
  background-color: #ffff; /* Color de fondo */
  border-radius: 0;
  border-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.custom-dropdown .dropdown-item {
  /* Estilos para cada elemento del menú */
  color: #042B4E; /* Color del texto */
  padding: 10px 20px; /* Espaciado interior */
  font-weight: 700;
  font-size: 14px;
}

.custom-dropdown .dropdown-item:hover {
  /* Estilos al pasar el mouse sobre los elementos del menú */
  background-color: #e9ecef; /* Cambiar color de fondo */
}

.banner {
  display: flex;
  flex-direction: row; /* Establece la dirección de los elementos como fila */
  align-items: center; /* Centra los elementos verticalmente */
  padding: 100px 0 50px 0;
}

.texto{
  flex: 1;
  padding-right: 20px;
}


.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 10px 10px;
  background-color: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5), rgba(74, 47, 189, 0.5));
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 30px;
  margin-bottom: 5px;
  display:inline-block;
  color: #042B4E;
}

.banner h1 {
  font-size: 65px;
  color: #042B4E;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #0B131D;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  text-align: justify;
}

.banner button {
  color: #282c34;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg{
  margin-left: 25px;
}

.img1banner {
  border-radius: '50%';
  margin-top: 40px;
}

.product-grid-container {
  margin-left: 80px; /* Margen a la izquierda automático */
  margin-right: auto; /* Margen a la derecha automático */
  max-width: 800px;
  margin-bottom: 50px;
}

.processesTitle {
  margin-bottom: 20px;
}

.processesTitle h1{
  font-weight: 700;
  color: #042B4E;
}

.processesTitle p{
  font-weight: 700;
  color: #0B131D;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  justify-content: center;
}

.product {
  padding: 20px;
  border-radius: 10px;
  text-align: justify;
  background-color: #F5F4F5;
}

.product h3 {
  margin-top: 0;
  color: #042B4E;
  font-weight: 700;
}

.product p {
  color: #0B131D;
}

.product button {
  background-color:#042B4E;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
}

.product button:hover {
  background-color: #0056b3;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate > wrap {
  border-right: 0.08em solid #666;
}

.footer-dark {
  padding:50px 0;
  color:#f0f9ff;
  background-color:#042B4E;
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:18px;
  text-align: justify;
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:16px;
  margin-bottom:0;
  text-align: justify;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.6;
}

.footer-dark ul a:hover {
  opacity:0.8;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-bottom:20px;
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark p {
  opacity:0.6;
  margin-bottom:0;
  text-align: justify;
}

.footer-dark .item.social {
  text-align:center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align:center;
    margin-top:20px;
  }
}

.footer-dark .item.social > a {
  font-size:20px;
  width:36px;
  height:36px;
  line-height:36px;
  display:inline-block;
  text-align:center;
  border-radius:50%;
  box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  margin:0 8px;
  color:#fff;
  opacity:0.75;
}

.footer-dark .item.social > a:hover {
  opacity:0.9;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

.rounded-circle-whatsapp {
  width: 70px;
  height: 70px;
  background-color: #50C878;
  color: #fff;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 35px;
  right: 35px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 38px;
  text-align: center;
  line-height: 50%
}

.rounded-circle-facebook {
  width: 70px;
  height: 70px;
  background-color: #0040af;
  color: #fff;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 110px;
  right: 35px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 38px;
  text-align: center;
  line-height: 50%
}

.rounded-circle-instagram {
  width: 70px;
  height: 70px;
  background-color: #be00c5;
  color: #fff;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 185px;
  right: 35px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 38px;
  text-align: center;
  line-height: 50%
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


