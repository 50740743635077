.bannerAbout {
    display: flex;
    flex-direction: row; /* Establece la dirección de los elementos como fila */
    align-items: center; /* Centra los elementos verticalmente */
    padding: 100px 0 50px 0;
    background-color: #042B4E;
  }
  
  .texto{
    flex: 1;
    padding-right: 20px;
  }
  
  
  .bannerAbout .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 10px 10px;
    background-color: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5), rgba(74, 47, 189, 0.5));
    font-size: 30px;
    margin-bottom: 5px;
    display:inline-block;
    color: #FFFFFF;
  }
  
  .bannerAbout h1 {
    font-size: 65px;
    color: #FFFFFF;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }
  
  .bannerAbout p {
    color: #F5F4F5;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
    text-align: justify;
  }
  
  .bannerAbout button {
    color: #282c34;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
  }
  
  .bannerAbout button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
  }
  
  .bannerAbout button:hover svg{
    margin-left: 25px;
  }
  
  .img1banner {
    border-radius: '50%';
    margin-top: 40px;
  }

  .contenedorMisionVision{
    padding: 60px 20px 100px 15px;
  }

  .contenedorMisionVision h3 {
    color: #042B4E;
    font-weight: 700;
    font-size: 45px;
  }